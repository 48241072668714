import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import {
  addCampCaptainErrors,
  guardianLoginErrors,
  guardianSignUpErrors,
  kidSignUpErrors,
  uploadErrors,
} from '../../constants/error.constants';
import { SITE_DOWN } from '../../constants/routes.constants';
import { removeSession } from '../../utils/session.utils';
import APIError from './api.error';
import ValidHttpStatusError from './api.error.valid';
import InvalidHttpStatusError from './invalid.http.status';
const USERNAME = 'username';
const PASSWORD = 'password';
const EMAIL = 'email';
const FAVORITE = 'favorites';
const TITLE = 'title';
const DESCRIPTION = 'description';
const RECAPTCHA = 'recaptcha';
/**
 * key: Custom Server Error 4XXXXX
 * value: object { key: field name from element, value: error to show user }
 */
function getErrorMessageObject(key, errorMessage) {
  return {
    fieldName: key,
    message: errorMessage,
  };
}
const errorMap = new Map([
  [400001, getErrorMessageObject(PASSWORD, 'Invalid user and/or password')],
  [400003, getErrorMessageObject(USERNAME, kidSignUpErrors.username.taken)],
  [400009, getErrorMessageObject(EMAIL, guardianSignUpErrors.email.taken)],
  [400010, getErrorMessageObject(USERNAME, kidSignUpErrors.username.minLength)],
  [400011, getErrorMessageObject(USERNAME, kidSignUpErrors.username.maxLength)],
  [400012, getErrorMessageObject(USERNAME, kidSignUpErrors.username.char_type)],
  [400013, getErrorMessageObject(USERNAME, kidSignUpErrors.username.profane)],
  [
    403001,
    getErrorMessageObject(USERNAME, guardianLoginErrors.unvalidated.required),
  ],
  [
    400014,
    getErrorMessageObject(PASSWORD, guardianSignUpErrors.password.minLength),
  ],
  [
    400015,
    getErrorMessageObject(PASSWORD, guardianSignUpErrors.password.strength),
  ],
  [400018, getErrorMessageObject(PASSWORD, kidSignUpErrors.password.minLength)],
  [400026, getErrorMessageObject(FAVORITE, kidSignUpErrors.favorites.profane)],
  [400027, getErrorMessageObject(TITLE, uploadErrors.title.profane)],
  [
    400028,
    getErrorMessageObject(DESCRIPTION, uploadErrors.description.profane),
  ],
  [
    405000,
    getErrorMessageObject(RECAPTCHA, guardianSignUpErrors.recaptcha.required),
  ],
  [
    422000,
    getErrorMessageObject(EMAIL, addCampCaptainErrors.email.invalid),
  ],
]);

/**
 * Handle response from server. A valid http status error is when the result code matches
 * the custom error code.
 * @param {object} response
 */

export const handleHttpStatusError = (response) => {
  if (errorMap.has(response.result_code)) {
    const error = errorMap.get(response.result_code);
    let values;
    try {
      values = response.error_info.split(':')[1].trim().split(/[ ,]+/);
    } catch {
      values = '';
    }
    const validError = new ValidHttpStatusError(
      error.message,
      error.fieldName,
      values,
      response.status,
      response.result_code,
      response
    );
    return Promise.reject(validError);
  } else {
    //possible a valid error but not found in map,
    if(response.result_code === 500) {
      // history.push(SITE_DOWN)
      <Redirect to={SITE_DOWN} />
    }
    const message = `Result code not found: ${response.result_code}`;
    const invalidError = new InvalidHttpStatusError(
      message,
      response.status,
      response
    );
    return Promise.reject(invalidError);
  }
};
/**
 * Server responded with valid http status code but returned
 * invalid json data
 * @param {object} response
 **/
export const handleJsonParseError = (response) => {
  const message = `Unable to parse error: ${response.status}`;
  const invalidError = new InvalidHttpStatusError(
    message,
    response.status,
    response
  );
  return Promise.reject(invalidError);
};
/**
 * A promise that pings google. If google responds then user should have internet.
 */
export const fetchInternetStatus = () => {
  return fetch('https://www.google.com', { mode: 'no-cors' })
    .then(() => {
      Promise.reject(new APIError('Unknown Error. Please Try Again Later.'));
    })
    .catch(() =>
      Promise.reject(
        new APIError('No internet detected. Please check your connection.')
      )
    );
};

/**
 * Delete session and force a browser refresh. This will clear out the user object
 *
 */
export const removeSessionAndRefresh = () => {
  removeSession();
  window.location.reload();
};
