import { multiLoginRepository } from "../../../repository/multilogin.repository";

const multiLoginChild = (dependent_id) => {
    return new Promise((resolve, reject) => {
        multiLoginRepository
            .multiLoginChildHandle(dependent_id)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const multiLoginParent = () => {
    return new Promise((resolve, reject) => {
        multiLoginRepository
            .multiLoginParentHandle()
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};
export const multiLoginViewModal = {
    multiLoginChild,
    multiLoginParent
}
