import { useHistory } from 'react-router-dom';
import { multiLoginViewModal } from '../components/views/multilogin/multilogin.view.model';
import { useAuth } from '../components/auth/Auth';

export const useHandleMultiLogin = () => {
  const history = useHistory();
  const { refreshUserState } = useAuth();

  const handleChildLogin = async (dependetId, path, isSelectedMood) => {
    try {
      if (dependetId) {
        await multiLoginViewModal.multiLoginChild(dependetId);
        if (isSelectedMood) {
          localStorage.setItem('checkin_modal', true);
        } else {
          localStorage.setItem('checkin_modal', false);
        }
        localStorage.setItem('isGuardianFromBrowse', false);
        history.push(path);
        refreshUserState();
      }
    } catch (err) {
      console.error('Error during multi-login:', err);
    }
  };
  const handleSwithChildToGuardian = async (
    guardianId,
    pathForNoSwitch,
    pathForRedrect
  ) => {
    try {
      if (guardianId) {
        history.push(pathForNoSwitch);
      } else {
        await multiLoginViewModal.multiLoginParent();
        localStorage.setItem('isGuardianFromBrowse', true);
        refreshUserState();
        history.push(pathForRedrect);
      }
    } catch (error) {
      console.error('Error during multi-login (parent):', error);
    }
  };

  return { handleChildLogin, handleSwithChildToGuardian };
};
