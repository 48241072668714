import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../auth/Auth';
import { LOGIN_ROUTE, MULTI_LOGIN } from '../../constants/routes.constants';
import { Spinner } from 'react-bootstrap';

function GuardianRoute({ component: Component, ...rest }) {
  const { guardian, isLoading } = useAuth();
  const isGuardianFromBrowse = localStorage.getItem('isGuardianFromBrowse');
  return (
    <div>
      {isLoading ? (
        //todo add spinner
        <div>
          <Spinner
            key={0}
            className="home-feed-spinner"
            animation="border"
            variant="info"
          />
        </div>
      ) : (
        <Route
          {...rest}
          render={(props) =>
            guardian ? (
              isGuardianFromBrowse ? (
                <Component {...props} />
              ) : (
                <Redirect to={MULTI_LOGIN} />
              )
            ) : (
              <Redirect to={'/'} />
            )
          }
        />
      )}
    </div>
  );
}

export default GuardianRoute;
