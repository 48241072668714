import React, { useEffect, useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import { getTaletreeFriendJson, getUserProfileUrl } from '../../../utils/camp.utils';
import Lottie from 'react-lottie-player';
import { Typography, Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { insightsViewModel } from '../dashboard/insights.view.model';
import "../../mui/binkiesCompliments.css";
import Happymood from '../../../assets/images/mood-img/Happy.svg';
import Calmmood from '../../../assets/images/mood-img/Calm.svg';
import Excitedmood from '../../../assets/images/mood-img/Excited.svg';
import Worriedmood from '../../../assets/images/mood-img/Worried.svg';
import Angrymood from '../../../assets/images/mood-img/Angry.svg';
import Scaredmood from '../../../assets/images/mood-img/Scared.svg';
import Confidentmood from '../../../assets/images/mood-img/Confident.svg';
import Annoyedmood from '../../../assets/images/mood-img/Annoyed.svg';
import { makeStyles } from '@mui/styles';
import { ANGRY, ANNOYED, CALM, CONFIDENT, EXCITED, HAPPY, SCARED, WORRIED } from '../../../constants/moodType.constants';
import { ANGRY_THOUGHTS_1, ANGRY_THOUGHTS_2, ANGRY_THOUGHTS_3, ANGRY_THOUGHTS_4, ANGRY_THOUGHTS_5, ANNOYED_THOUGHTS_1, ANNOYED_THOUGHTS_2, ANNOYED_THOUGHTS_3, ANNOYED_THOUGHTS_4, ANNOYED_THOUGHTS_5, CALM_THOUGHTS_1, CALM_THOUGHTS_2, CALM_THOUGHTS_3, CALM_THOUGHTS_4, CALM_THOUGHTS_5, CONFIDENT_THOUGHTS_1, CONFIDENT_THOUGHTS_2, CONFIDENT_THOUGHTS_3, CONFIDENT_THOUGHTS_4, CONFIDENT_THOUGHTS_5, EXCITED_THOUGHTS_1, EXCITED_THOUGHTS_2, EXCITED_THOUGHTS_3, EXCITED_THOUGHTS_4, EXCITED_THOUGHTS_5, HAPPY_THOUGHTS_1, HAPPY_THOUGHTS_2, HAPPY_THOUGHTS_3, HAPPY_THOUGHTS_4, SCARED_THOUGHTS_1, SCARED_THOUGHTS_2, SCARED_THOUGHTS_3, SCARED_THOUGHTS_4, SCARED_THOUGHTS_5, WORRIED_THOUGHTS_1, WORRIED_THOUGHTS_2, WORRIED_THOUGHTS_3, WORRIED_THOUGHTS_4, WORRIED_THOUGHTS_5 } from '../../../constants/moodThoughts.constants';
import EventEmitter from '../../../utils/events';
import { DAILY_CHECKINS_REPLY } from '../../../constants/companionPrompt.constants';

const useStyles = makeStyles((theme) => ({
    toggleButtonGroup: {
        justifyContent: 'center'
    },
    toggleButton: {
        border: 'none !important',
        backgroundColor: 'transparent !important'
    }
}));

const thoughtColors = [
    '#ffd29f', '#C0FECF', '#F2F6AB', '#D6D5FD', "#B3E5FE", "#9CF2F1", "#FDE988", "#ffd6fa", "#d6fbff", "#ddd6ff"
]

const happyThoughts = [
    HAPPY_THOUGHTS_1, HAPPY_THOUGHTS_2, HAPPY_THOUGHTS_3, HAPPY_THOUGHTS_4
]

const excitedThoughts = [
    EXCITED_THOUGHTS_1, EXCITED_THOUGHTS_2, EXCITED_THOUGHTS_3, EXCITED_THOUGHTS_4, EXCITED_THOUGHTS_5
]

const calmThoughts = [
    CALM_THOUGHTS_1, CALM_THOUGHTS_2, CALM_THOUGHTS_3, CALM_THOUGHTS_4, CALM_THOUGHTS_5
]

const annoyedThoughts = [
    ANNOYED_THOUGHTS_1, ANNOYED_THOUGHTS_2, ANNOYED_THOUGHTS_3, ANNOYED_THOUGHTS_4, ANNOYED_THOUGHTS_5
]

const confidentThoughts = [
    CONFIDENT_THOUGHTS_1, CONFIDENT_THOUGHTS_2, CONFIDENT_THOUGHTS_3, CONFIDENT_THOUGHTS_4, CONFIDENT_THOUGHTS_5
]

const scaredThoughts = [
    SCARED_THOUGHTS_1, SCARED_THOUGHTS_2, SCARED_THOUGHTS_3, SCARED_THOUGHTS_4, SCARED_THOUGHTS_5
]

const angryThoughts = [
    ANGRY_THOUGHTS_1, ANGRY_THOUGHTS_2, ANGRY_THOUGHTS_3, ANGRY_THOUGHTS_4, ANGRY_THOUGHTS_5
]

const worriedThoughts = [
    WORRIED_THOUGHTS_1, WORRIED_THOUGHTS_2, WORRIED_THOUGHTS_3, WORRIED_THOUGHTS_4, WORRIED_THOUGHTS_5
]

export default function KidsMoodModal(props) {
    const DEFAULT_TEXT = "Hey there, little friend! How's your happy-o-meter doing today?";
    const HAPPY_TEXT = 'It’s amazing that you are happy today, yay!';
    const EXCITED_TEXT = 'You’re really excited, how amazing!';
    const CALM_TEXT = 'You’re super calm today, just like me!';
    const ANNOYED_TEXT =
      'You seem nervous today, oh no! If you need any help I’m here.';
    const CONFIDENT_TEXT = `I see you're tired, what happened?`;
    const SCARED_TEXT =
      'You seem sad today, tell me why later and I will help you.';
    const ANGRY_TEXT = `You seem bored, what's on your mind?`;
    const WORRIED_TEXT = 'You seem worried, what’s on your mind?';
    const { showMoodModal, setShowMoodModal } = props;
    const [selectedFeeling, setSelectedFeeling] = useState(null);
    const [showReason, setShowReason] = useState(false);
    const [mood, setMood] = React.useState(null);
    const [comment, setComment] = React.useState(null);
    const [speechText, setSpeechText] = useState(DEFAULT_TEXT);
    const classes = useStyles();
    const [thoughtArray, setThoughtArray] = useState([]);
    const [selectedFeelingReasons, setSelectedFeelingReasons] = useState([]);

    const handleFeelingClick = (feeling) => {
        setSelectedFeeling(feeling);
    };
    const handleClose = () => {
        setShowMoodModal(false);
        localStorage.setItem('checkin_modal', false)
    }

    const { dependent, setUser } = useAuth();

    const getFeelingsList = () => {
        if (selectedFeeling) {
            insightsViewModel.getFeelingsList(selectedFeeling).then((res) => {
                setSelectedFeelingReasons(res)
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const onSubmit = () => {
        const date = new Date()
        const data = {
            year: String(date.getFullYear()),
            month: String(date.getMonth() + 1),
            day: String(date.getDate()),
            mood: (selectedFeeling),
            dependent_id: dependent.id,
            comment: comment
        }
        insightsViewModel.addKidMood(data).then((res) => {
            setUser(res?.data?.dependent)
            setShowMoodModal(false)
            setSelectedFeeling(null);
            setMood(null);
            setComment(null);
            setShowReason(false);
            EventEmitter.dispatch('show_prompt', {
                showPrompt: 'block',
                promptType: DAILY_CHECKINS_REPLY,
                mood: mood
            })
            if (props?.setTodayMood) {
              props.setTodayMood([res.data]);
            }
        }).catch((err) => {
            console.log(err)
            setShowMoodModal(false)
        })
    }

    const handleMoodChange = (event, newMood) => {
        if (newMood !== null) {
            setMood(newMood);
            setSelectedFeeling(newMood);
            switch (newMood) {
                case HAPPY:
                    setSpeechText(HAPPY_TEXT);
                    setThoughtArray(happyThoughts);
                    break;
                case EXCITED:
                    setSpeechText(EXCITED_TEXT);
                    setThoughtArray(excitedThoughts);
                    break;
                case CALM:
                    setSpeechText(CALM_TEXT);
                    setThoughtArray(calmThoughts);
                    break;
                case ANNOYED:
                    setSpeechText(ANNOYED_TEXT);
                    setThoughtArray(annoyedThoughts);
                    break;
                case CONFIDENT:
                    setSpeechText(CONFIDENT_TEXT);
                    setThoughtArray(confidentThoughts);
                    break;
                case SCARED:
                    setSpeechText(SCARED_TEXT);
                    setThoughtArray(scaredThoughts);
                    break;
                case ANGRY:
                    setSpeechText(ANGRY_TEXT);
                    setThoughtArray(angryThoughts);
                    break;
                case WORRIED:
                    setSpeechText(WORRIED_TEXT);
                    setThoughtArray(worriedThoughts);
                    break;
                default:
                    setSpeechText(DEFAULT_TEXT)
                    break;
            }
        }
    };

    return (
        <Modal className="signupPopup kid-mood mobile-v-popup gardian auth-popup right-curved-div kid-feeling-mood" onHide={handleClose} show={showMoodModal} centered backdrop="static" keyboard={false}>
            {/* <Modal.Header closeButton /> */}
            <Modal.Body >
                <div className='row bg-blue border-40'>
                    <div className="col-md-6 col-lg-5 p-4 bg-blue border-left buble-relative overflow-hidden">
                        <div className="voice-buble buuble-bounce" >
                            <p id='voice-text'>{speechText}</p>
                        </div>
                         {/*<div className="text-left">
                            <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> 
                            <div className="" style={{ marginTop: '10rem' }}>
                                 <Lottie
                                    loop
                                    animationData={getSoundWaveJson()}
                                    play={isPlay}
                                    className='animate-svg'
                                /> 
                            </div>
                        </div>*/}
                        <div className="cartoon-img" style={{ marginTop: "80px" }}>
                            <Lottie
                                loop={false}
                                animationData={getTaletreeFriendJson(dependent?.profile?.taletree_friend)}
                                play
                                className="w-100 cartoon-top"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 v-flex bg-white border-right right-curve auth-section white-right-arrow toparrow right-bottom-curve feeling-today">
                        <Box sx={{ textAlign: 'center', padding: '20px' }}>
                            <Typography variant="h4" gutterBottom>
                                How are you feeling today?
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Just wanted to know how your day is going?
                            </Typography>
                            <Box className="thoughts-box-div kid-mood" mt={3} justifyContent="center" display="flex" alignItems="center">
                                <Box className="thoughts-box" ml={2}>
                                    <Typography variant="h6" gutterBottom className={mood ? mood : 'no-mood'}>
                                        <img src={getUserProfileUrl(dependent)} alt="Comment Icon" style={{ borderRadius: '100%' }} className={classes.icon} /> I am...{mood && <span className='mood-thought mood-thoughtss'> {mood.charAt(0).toUpperCase() + mood.slice(1)}</span>}
                                    </Typography>
                                </Box>
                            </Box>
                            {showReason ?
                                <div className='simply-thought-main' style={{ background: '#fff' }}>
                                    <p className='simply-because'>Because</p>
                                    <div className='simply-thought'>
                                        {selectedFeelingReasons.length > 0 && selectedFeelingReasons.map((thought, index) => {
                                            return (
                                                <span className={`thought${index + 1} ${comment === thought?.reason && 'active'}`} style={{ background: thoughtColors[index] }} onClick={() => setComment(thought?.reason)}>
                                                    {thought?.reason}
                                                </span>
                                            )
                                        })}
                                    </div>
                                    <Box sx={{ marginTop: '20px' }} >
                                        <div className="row w-100 mobile-btn-flex gardian-plan-btns-div" >
                                            <div className="col-12 col-md-6">
                                                <Button className=" grey-btn btn w-100" onClick={() => setShowReason(false)} style={{ textDecoration: 'none' }}>Back</Button>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <Button className='gred-btn w-100' onClick={onSubmit} variant="contained" color="primary" disabled={!comment}>
                                                    Finish
                                                </Button>
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                                :
                                <>
                                    <Box className="moods-div kid-mood-feeling" sx={{ marginTop: '50px' }} display="flex" alignItems="center" mb={3}>
                                        <ToggleButtonGroup
                                            value={mood}
                                            exclusive
                                            onChange={handleMoodChange}
                                            aria-label="mood"
                                            sx={{ ml: 2 }}
                                            className={`feelings-icon-button-group ${classes.toggleButtonGroup}`}
                                        >

                                            <ToggleButton value={HAPPY} aria-label="happy" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Happymood} className='happy' />
                                            </ToggleButton>
                                            <ToggleButton value={EXCITED} aria-label="excited" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Excitedmood} className='excited' />
                                            </ToggleButton>
                                            <ToggleButton value={CALM} aria-label="calm" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Calmmood} className='calm' />
                                            </ToggleButton>
                                            <ToggleButton value={ANNOYED} aria-label="annoyed" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Annoyedmood} className='annoyed' />
                                            </ToggleButton>
                                            <ToggleButton value={CONFIDENT} aria-label="confident" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Confidentmood} className='confident' />
                                            </ToggleButton>
                                            <ToggleButton value={SCARED} aria-label="scared" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Scaredmood} className='scared' />
                                            </ToggleButton>
                                            <ToggleButton value={ANGRY} aria-label="angry" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Angrymood} className='angry' />
                                            </ToggleButton>
                                            <ToggleButton value={WORRIED} aria-label="worried" className={`feelings-icon-button ${classes.toggleButton}`}>
                                                <Image src={Worriedmood} className='worried' />
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                    <Box sx={{ marginTop: '90px' }}>
                                        <Button
                                            className='gred-btn w-80'
                                            onClick={() => { setShowReason(true); getFeelingsList(); }}
                                            variant="contained"
                                            disabled={!selectedFeeling}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </>
                            }
                        </Box>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}