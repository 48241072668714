import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { getGoogleAnalyticsTrackingId } from '../utils/config.utils';

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: getGoogleAnalyticsTrackingId(),
        gaOptions: {
          anonymizeIp: true
        }
      }
    ])
    // ReactGA.initialize(getGoogleAnalyticsTrackingId(), {
    //   debug: true 
    // });
    // ReactGA.set({ anonymizeIp: true });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      // ReactGA.pageview(location.pathname + location.search);
      ReactGA.send({ hitType: "pageview", page: `${location.pathname}${location.search}}` });
    }
  }, [initialized, location]);
};

export default usePageTracking;
