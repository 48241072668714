import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import MultiBg from '../../../assets/multi/Kid_background@2x.jpg';
import GuardianDefaultImg from '../../../assets/profile/default-profile-guardian.svg';
import { useAuth } from '../../auth/Auth';
import { multiLoginViewModal } from '../multilogin/multilogin.view.model';
import {
  GUARDIAN_SETTINGS_ROUTE,
  HOME_FEED,
  MULTI_LOGIN,
} from '../../../constants/routes.constants';
import { getUserProfileUrl } from '../../../utils/camp.utils';
import { insightsViewModel } from '../dashboard/insights.view.model';
import { getMoodIcon } from '../../../utils/mood.utils';
import PlusImage from '../../../assets/multi/PlusImage.png';

import {
  getCampCountdown,
  getDependentSubscriptionBadge,
} from '../../../utils/utils';
import AddChild from '../onboarding/signup_popups/AddChild';
import TaletreeLoader from '../taletree_loader/TaletreeLoader';
import { useHandleMultiLogin } from '../../../utils/multilogin';

export const nudge = keyframes`
0% {
  transform: translateY(0);
}
25% {
  transform: translateY(-20px);
}
50% {
  transform: translateY(0);
}
75% {
  transform: translateY(0px);
}
100% {
  transform: translateY(0);
}
`;

const MultiLogin = () => {
  const history = useHistory();
  const { guardian, refreshUserState, isLoading } = useAuth();
  const [showAddChild, setShowAddChild] = useState(false);
  // const handleMultiLogin = async (dependent_id) => {
  //   try {
  //     if (dependent_id) {
  //       await multiLoginViewModal.multiLoginChild(dependent_id);
  //       localStorage.setItem('isGuardianFromBrowse', true);
  //       history.push(HOME_FEED);
  //       refreshUserState();
  //     }
  //   } catch (err) {
  //     console.error('Error during multi-login:', err);
  //   }
  // };
  const { handleChildLogin } = useHandleMultiLogin();
  const [todayMood, setTodayMood] = useState([]);

  const getColorForText = (text) => {
    const colorMap = {
      E: '#8A2BE2',
      V: '#ff00e5',
      S: '#47cf73',
      X: 'red',
    };

    return colorMap[text] || '';
  };

  const handleParentLogin = async () => {
    if (guardian?.id) {
      history.push(GUARDIAN_SETTINGS_ROUTE);
    } else {
      try {
        await multiLoginViewModal.multiLoginParent();
        history.push(MULTI_LOGIN);
        refreshUserState();
      } catch (error) {
        console.log(error, 'error in  parent login ');
      }
    }
  };
  const getMood = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = String(date.getDate());

    const dependents = guardian?.dependents || [];

    const promises = dependents.map((dependent) => {
      const data = {
        dependent_id: dependent.id,
        year,
        month,
        mood: '',
      };

      return insightsViewModel
        .getKidMood(data)
        .then((res) => {
          const moodEntry = res.data.find((obj) => obj.day === day);
          return {
            dependentId: dependent.id,
            mood: moodEntry ? moodEntry.mood : '',
          };
        })
        .catch((err) => {
          console.error(
            `Error fetching mood for dependent ${dependent.id}:`,
            err
          );
          return {
            dependentId: dependent.id,
            mood: '',
          };
        });
    });

    Promise.all(promises)
      .then((results) => {
        setTodayMood(results);
      })
      .catch((err) => {
        console.error('Error in Promise.all:', err);
      });
  };

  useEffect(() => {
    if (guardian?.dependents && guardian.dependents.length > 0) {
      getMood();
    }
  }, [guardian]);

  return (
    <Box
      className="multi_login_parent_box"
      sx={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#141414',
        backgroundImage: `url(${MultiBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center',
      }}
    >
      {isLoading ? (
        <TaletreeLoader />
      ) : (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 3,
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              fontSize: '2.5em',
            }}
          >
            Who's watching taletree today?
          </Typography>
          <Grid
            container
            justifyContent="center"
            sx={{
              textAlign: 'center',
              alignItems: {
                xl: 'flex-start',
                lg: 'flex-start',
                md: 'flex-start',
                sm: 'flex-start',
                xs: 'center',
              },
              flexDirection: { xs: 'column-reverse', sm: 'row' },
            }}
          >
            {/* Children Profiles */}
            <Grid
              item
              xl={8}
              lg={8}
              md={6}
              sm={6}
              xs={12}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                justifyContent: { xs: 'center', sm: 'flex-start' },
                // height: {  lg: '70vh', md: '75vh', sm: '63vh' },
                overflowY: 'scroll',
                maxWidth: 'fit-content !important',
                // paddingTop: '3%',
                height: '450px',
                '@media (max-width: 1299px)': {
                  height: '500px', // Custom padding for screens below 600px
                },
                '@media (max-width: 900px)': {
                  height: '460px', // Custom padding for screens below 600px
                },
                '@media (max-width: 767px)': {
                  height: '480px', // Custom padding for screens below 600px
                },
              }}
            >
              {guardian?.dependents?.map((profile, index) => {
                const profileIconText = getDependentSubscriptionBadge(profile);
                return (
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={index}
                    sx={{
                      maxWidth: {
                        xl: '100%',
                        lg: '100%',
                        md: '100%',
                        sm: '100%',
                        xs: '100%',
                      },
                      flex: '0 1 180px',
                      cursor: 'pointer',
                      position: 'relative',
                      padding: '30px 10px 0px',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                      position: 'sticky',
                      right: '0px',
                      margin: '0% 0% 0%',
                      '&:hover': {
                        transform: 'translateY(-10px)',
                      },
                      '&:hover .profileIconText': {
                        animation: `${nudge} 1.5s ease-in-out infinite`,
                      },
                    }}
                  >
                    <Box
                      component="div"
                      // onClick={() => handleMultiLogin(profile?.id)}
                      onClick={() => {
                        handleChildLogin(
                          profile?.id,
                          HOME_FEED,
                          profile?.profile?.ask_for_mood,
                        );
                      }}
                      sx={{
                        position: 'relative',
                        '&:hover .popOutImg': {
                          opacity: 1,
                          visibility: 'visible',
                          transform: 'translate(-70%, -60%) scale(1)',
                        },
                      }}
                    >
                      {/* Profile Image */}
                      <Box
                        component="img"
                        src={getUserProfileUrl(profile)}
                        alt={profile?.first_name}
                        sx={{
                          width: '130px',
                          height: '130px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            boxShadow: '0 0 20px rgba(255,255,255,0.5)',
                          },
                        }}
                      />
                      {/* Pop-out Image (shown above the profile image when hovered) */}
                      {todayMood[index]?.mood && (
                        <Box
                          className="popOutImg"
                          sx={{
                            position: 'absolute',
                            top: '12px',
                            left: '85%',
                            width: '50px',
                            height: '50px',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            // boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                            opacity: 0,
                            visibility: 'hidden',
                            transition: 'all 0.3s ease',
                            zIndex: 2,
                          }}
                        >
                          <Box
                            component="img"
                            src={getMoodIcon(todayMood[index]?.mood)}
                            alt="Pop-out"
                            sx={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', // Vertically center elements
                        flexDirection: 'row',
                        gap: '8px',
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 1,
                          fontSize: '15px',
                          textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
                          fontWeight: 600,
                          textAlignL: 'center',
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {profile?.profile?.nick_name
                          ? profile?.profile?.nick_name
                          : profile.first_name}
                      </Typography>
                      {/* Profile Icon */}
                      {profileIconText && (
                        <Box
                          className="profileIconText"
                          sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            background: getColorForText(profileIconText),
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline !important',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                            transition: 'all 0.3s ease',
                            fontWeight: 600,
                          }}
                        >
                          {profileIconText}
                        </Box>
                      )}
                    </Box>
                    {profile?.profile?.group?.id && (
                      <Box>
                        <Typography
                          sx={{
                            padding: '5px 10px',
                            borderRadius: '15px',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            fontSize: { lg: '15px', md: '14px', sm: '12px' },
                          }}
                        >
                          Next Camp:
                          <span>{getCampCountdown(profile?.profile)}</span>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                );
              })}
              {/* Plus icon place below */}
              <Grid
                item
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={12}
                sx={{
                  maxWidth: {
                    xl: '100%',
                    lg: '100%',
                    md: '100%',
                    sm: '100%',
                    xs: '100%',
                  },
                  flex: '0 1 180px',
                  cursor: 'pointer',
                  position: 'relative',
                  padding: '30px 10px 0px',
                  borderRadius: '10px',
                  transition: 'all 0.3s ease',
                  position: 'sticky',
                  right: '0px',
                  margin: '0% 0% 0%',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                  '&:hover .profileIconText': {
                    animation: `${nudge} 1.5s ease-in-out infinite`,
                  },
                }}
              >
                <Box
                  component="div"
                  onClick={() => setShowAddChild(true)}
                  sx={{
                    position: 'relative',
                    '&:hover .popOutImg': {
                      opacity: 1,
                      visibility: 'visible',
                      transform: 'translate(-70%, -60%) scale(1)',
                    },
                  }}
                >
                  {/* Profile Image */}
                  <Box
                    // component="img"
                    // src={PlusImage}
                    alt="addIcon"
                    sx={{
                      margin: 'auto',
                      width: '130px',
                      height: '130px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        boxShadow: '0 0 20px rgba(255,255,255,0.5)',
                      },
                    }}
                  >
                    <div
                      // role="button"
                      // onClick={redirectPage}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0px 10px',
                        borderTop: 'var(--side-bar-border)', // Uses your CSS variable
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: 'var(--side-bar-header-text-color)', // Uses your CSS variable
                      }}
                    >
                      <span
                        style={{
                          width: '130px',
                          height: '130px',
                          borderRadius: '50%',
                          backgroundColor: '#58ede6',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '80px',
                          fontWeight: 600,
                          color: '#fff',
                        }}
                      >
                        +
                      </span>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center', // Vertically center elements
                      flexDirection: 'row',
                      gap: '8px',
                      // maxWidth: '130px',
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 1,
                        fontSize: '15px',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
                        fontWeight: 600,
                        textAlignL: 'center',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Add a kid
                    </Typography>
                  </Box>
                  {/* Pop-out Image (shown above the profile image when hovered) */}
                </Box>
              </Grid>
            </Grid>
            {guardian?.dependents && (
              <Box
                sx={{
                  display: {
                    xl: 'block',
                    lg: 'block',
                    md: 'block',
                    sm: 'block',
                    xs: 'none',
                  },
                  width: '1px',
                  height: '150px',
                  backgroundColor: 'rgba(255,255,255,0.3)',
                  margin: '2% 2% 0%',
                  alignSelf: 'flex-start',
                }}
              />
            )}
            <Grid
              component="div"
              onClick={() => {
                // history.push(GUARDIAN_SETTINGS_ROUTE);
                handleParentLogin();
              }}
              item
              sx={{
                borderRadius: '10px',
                '&:hover': {
                  transform: 'translateY(-10px)',
                },
                margin: '30px 0px 0px',
              }}
            >
              <Box
                component="img"
                src={
                  guardian?.picture_url
                    ? guardian?.picture_url
                    : GuardianDefaultImg
                }
                alt="Guardian"
                sx={{
                  width: '130px',
                  height: '130px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  border: '2px solid #fff',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    boxShadow: '0 0 20px rgba(255,255,255,0.5)',
                  },
                }}
              />

              <Typography
                sx={{
                  mt: 1,
                  fontSize: '15px',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
                }}
              >
                Guardian
              </Typography>
              <Typography
                sx={{
                  fontSize: '1em',
                  mt: 0.5,
                  color: '#ccc',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
                }}
              >
                {guardian?.first_name} {guardian?.last_name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <AddChild showModal={showAddChild} setShowModal={setShowAddChild} />
    </Box>
  );
};

export default MultiLogin;
