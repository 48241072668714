import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import MultiBg from '../../../assets/multi/Kid_background@2x.jpg';
import GuardianDefaultImg from '../../../assets/profile/default-profile-guardian.svg';
import { useAuth } from '../../auth/Auth';
import { multiLoginViewModal } from '../multilogin/multilogin.view.model';
import {
  DASHBOARD_ROUTE,
  HOME_FEED,
} from '../../../constants/routes.constants';
import { useHistory } from 'react-router-dom';
import { getUserProfileUrl } from '../../../utils/camp.utils';
import { insightsViewModel } from '../dashboard/insights.view.model';
import { useEffect } from 'react';
import { getMoodIcon } from '../../../utils/mood.utils';
import { keyframes } from '@emotion/react';
import {
  getCampCountdown,
  getDependentSubscriptionBadge,
} from '../../../utils/utils';

const nudge = keyframes`
0% {
  transform: translateY(0);
}
25% {
  transform: translateY(-20px);
}
50% {
  transform: translateY(0);
}
75% {
  transform: translateY(0px);
}
100% {
  transform: translateY(0);
}
`;

const MultiLogin = () => {
  const history = useHistory();
  const { guardian, refreshUserState } = useAuth();
  const handleMultiLogin = async (dependent_id) => {
    try {
      if (dependent_id) {
        await multiLoginViewModal.multiLoginChild(dependent_id);
        history.push(HOME_FEED);
        refreshUserState();
      }
    } catch (err) {
      console.error('Error during multi-login:', err);
    }
  };
  const [todayMood, setTodayMood] = useState([]);

  const getColorForText = (text) => {
    const colorMap = {
      E: '#8A2BE2',
      V: '#ff00e5',
      S: '#47cf73',
      X: 'red',
    };

    return colorMap[text] || '';
  };

  const getMood = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = String(date.getDate());

    const dependents = guardian?.dependents || [];

    const promises = dependents.map((dependent) => {
      const data = {
        dependent_id: dependent.id,
        year,
        month,
        mood: '',
      };

      return insightsViewModel
        .getKidMood(data)
        .then((res) => {
          const moodEntry = res.data.find((obj) => obj.day === day);
          return {
            dependentId: dependent.id,
            mood: moodEntry ? moodEntry.mood : '',
          };
        })
        .catch((err) => {
          console.error(
            `Error fetching mood for dependent ${dependent.id}:`,
            err
          );
          return {
            dependentId: dependent.id,
            mood: '',
          };
        });
    });

    Promise.all(promises)
      .then((results) => {
        setTodayMood(results);
      })
      .catch((err) => {
        console.error('Error in Promise.all:', err);
      });
  };

  useEffect(() => {
    if (guardian?.dependents && guardian.dependents.length > 0) {
      getMood();
    }
  }, [guardian]);

  return (
    <Box
      sx={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#141414',
        backgroundImage: `url(${MultiBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center',
      }}
    >
      <Box sx={{}}>
        <Typography
          variant="h2"
          sx={{
            mb: 3,
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            fontSize: '2.5em',
          }}
        >
          Select a profile
        </Typography>
        <Grid
          container
          justifyContent="center"
          sx={{
            textAlign: 'center',

            flexDirection: { xs: "column-reverse", sm: "row", },
          }}
        >
          {/* Children Profiles */}
          <Grid
            item
            xl={8}
            lg={8}
            md={6}
            sm={6}
            xs={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { xs: "center", sm: "flex-start", },
              height: '60vh',
              overflowY: 'scroll',
              padding: '0px !important',
              '@media only screen and (min-width:1500px)': {
                overflowY: 'scroll',
                height: '30vh', // Example: Adjust height for larger screens
              },
            }}
          >
            {guardian?.dependents?.map((profile, index) => {
              const profileIconText = getDependentSubscriptionBadge(profile);
              return (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  key={index}
                  sx={{
                    flex: '0 1 180px',
                    cursor: 'pointer',
                    position: 'relative',
                    padding: '10px',
                    borderRadius: '10px',
                    transition: 'all 0.3s ease',
                    position: 'sticky',
                    right: '0px',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                    },
                    '&:hover .profileIconText': {
                      animation: `${nudge} 1.5s ease-in-out infinite`,
                    },
                    margin: '20px 0px',
                  }}
                >
                  <Box
                    component="div"
                    onClick={() => handleMultiLogin(profile?.id)}
                    sx={{
                      position: 'relative',
                      '&:hover .popOutImg': {
                        opacity: 1,
                        visibility: 'visible',
                        transform: {
                          xl: "translate(-220%, -60%) scale(1)", lg: "translate(-100%, -60%) scale(1)",
                          md: "translate(-80%, -70%) scale(1)", xs: "translate(-80%, -70%) scale(1)",
                        },
                      },
                    }}
                  >
                    {/* Profile Image */}
                    <Box
                      component="img"
                      src={getUserProfileUrl(profile)}
                      alt={profile?.first_name}
                      sx={{
                        width: '130px',
                        height: '130px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                          boxShadow: '0 0 20px rgba(255,255,255,0.5)',
                        },
                      }}
                    />
                    {/* Pop-out Image (shown above the profile image when hovered) */}
                    {todayMood[index]?.mood && (
                      <Box
                        className="popOutImg"
                        sx={{
                          position: 'absolute',
                          top: '12px',
                          left: '85%',
                          width: '50px',
                          height: '50px',
                          borderRadius: '10px',
                          overflow: 'hidden',
                          // boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                          opacity: 0,
                          visibility: 'hidden',
                          transition: 'all 0.3s ease',
                          zIndex: 2,
                        }}
                      >
                        <Box
                          component="img"
                          src={getMoodIcon(todayMood[index]?.mood)}
                          alt="Pop-out"
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center', // Vertically center elements
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 1,
                        fontSize: '15px',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
                        fontWeight: 600,
                        textAlignL: 'center',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {profile?.profile?.nick_name ? profile?.profile?.nick_name : profile.first_name}
                    </Typography>
                    {/* Profile Icon */}
                    {profileIconText && (
                      <Box
                        className="profileIconText"
                        sx={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          background: getColorForText(profileIconText),
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline !important',
                          fontWeight: 'bold',
                          fontSize: '15px',
                          boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                          transition: 'all 0.3s ease',
                          fontWeight: 600,
                        }}
                      >
                        {profileIconText}
                      </Box>
                    )}
                  </Box>
                  {profile?.profile?.group?.id && (
                    <Box>
                      <Typography
                        sx={{
                          padding: '5px 10px',
                          borderRadius: '15px',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        }}
                      >
                        Next Camp:
                        <span>{getCampCountdown(profile?.profile)}</span>
                      </Typography>
                    </Box>
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
              width: '1px',
              height: '150px',
              backgroundColor: 'rgba(255,255,255,0.3)',
              margin: '0 20px',
              alignSelf: 'center',
            }}
          />
          <Grid
            component="div"
            onClick={() => history.push(DASHBOARD_ROUTE)}
            item
            sx={{
              flex: '0 1 180px',
              cursor: 'pointer',
              position: 'relative',
              padding: '10px',
              borderRadius: '10px',
              '&:hover': {
                transform: 'translateY(-10px)',
              },
            }}
          >
            <Box
              component="img"
              src={
                guardian?.picture_url
                  ? guardian?.picture_url
                  : GuardianDefaultImg
              }
              alt="Guardian"
              sx={{
                width: '130px',
                height: '130px',
                borderRadius: '50%',
                objectFit: 'cover',
                border: '2px solid #fff',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0 0 20px rgba(255,255,255,0.5)',
                },
              }}
            />

            <Typography
              sx={{
                mt: 1,
                fontSize: '15px',
                textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
              }}
            >
              Guardian
            </Typography>
            <Typography
              sx={{
                fontSize: '1em',
                mt: 0.5,
                color: '#ccc',
                textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
              }}
            >
              {guardian?.first_name} {guardian?.last_name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MultiLogin;
