import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  Textarea,
} from '@mui/joy';
import { adminSpecialCampViewModel } from './specialcamp.view.model';
import timezones from 'timezones-list';
import { useHistory } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { Grid } from '@mui/material';
import SendMailModal from '../commonComponent/SendMailModal';
import { useAuth } from '../../../../components/auth/Auth';
import { inputAutofillCss } from '../customData/Inputcss';
import { format, parse } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroller';
import TaletreeLoader from '../../../../components/views/taletree_loader/TaletreeLoader';

const SpecialCampForm = ({
  id,
  initialData,
  formData,
  setFormData,
  updatefile,
  setupdatefile,
  isPublished,
}) => {
  const history = useHistory();
  const { showAlert } = useAuth();
  const [expertList, setexpertList] = useState([]);
  const [isUpdateImage, setIsUpdateImage] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [availableCamps, setavailableCamps] = useState([]);
  const [formDataError, setFormDataError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [querySchool, setQuerySchool] = useState({
    limit: 10,
    has_more: true,
    starting_after: null,
  });
  const [schoolListIsLoading, setSchoolListIsLoading] = useState(false);
  const handleDropdownChange = (e, value) => {
    setFormData({ ...formData, pay_by: [value] });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setFormData({ ...formData, picture: e.target.files[0] });
    setIsUpdateImage(true);
    setupdatefile('');
  };

  const handleSelectChange = (e, value) => {
    setFormData({ ...formData, camp: value });
  };
  const handleExpertSelectChange = (e, value) => {
    setFormData({ ...formData, created_by: value });
  };





  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const urlRegex =
      /^(https?:\/\/)(www\.)?[a-zA-Z0-9@:%._\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._\+~#?&//=]*)$/;

    // Check if required fields are filled
    if (!formData.name) errors.push('Title is required.');
    if (!formData.subtitle) errors.push('Subtitle is required.');
    if (!formData.description) errors.push('Description is required.');
    if (!formData.zoom_link) {
      errors.push('Zoom link is required.');
    } else if (!urlRegex.test(formData.zoom_link)) {
      errors.push('Invalid Zoom link or URL.');
    }
    if (!formData.eventStartDate || !formData.eventStartTime)
      errors.push('Event start date and time are required.');
    if (!formData.eventPublishDate || !formData.eventPublishTime)
      errors.push('Event publish date and time are required.');

    // Compare event start date & time with publish date & time
    const eventStartDateTime = new Date(
      formData.eventStartDate + ' ' + formData.eventStartTime
    );
    const eventPublishDateTime = new Date(
      formData.eventPublishDate + ' ' + formData.eventPublishTime
    );

    // Compare only the dates first
    const eventStartDate = eventStartDateTime.toISOString().split('T')[0]; // Get the date part
    const eventPublishDate = eventPublishDateTime.toISOString().split('T')[0]; // Get the date part
    const combinedStartDateTime = parse(
      `${formData.eventStartDate}T${formData.eventStartTime}`,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    );
    const combinedPublishDateTime = parse(
      `${formData.eventPublishDate}T${formData.eventPublishTime}`,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    );

    const eventStartDateFormate = format(
      combinedStartDateTime,
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const eventPublishDateFormate = format(
      combinedPublishDateTime,
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    // Compare dates
    if (eventStartDate === eventPublishDate) {
      // If dates are the same, compare the times
      if (eventStartDateTime.getTime() <= eventPublishDateTime.getTime()) {
        setFormDataError([
          ...formDataError,
          {
            DateRangeError:
              'Event start date and time should be greater than publish date and time.',
          },
        ]);
        setTimeout(() => {
          setFormDataError([]);
        }, 2000);
        return;
      }
    } else if (eventStartDate < eventPublishDate) {
      // If the start date is before the publish date, set error
      setFormDataError([
        ...formDataError,
        {
          DateRangeError:
            'Event start date and time should be greater than publish date and time.',
        },
      ]);
      setTimeout(() => {
        setFormDataError([]);
      }, 2000);
      return;
    }
    const currentTime = Date.now(); // Get the current timestamp
    if (combinedPublishDateTime.getTime() <= currentTime) {
      setFormDataError([
        ...formDataError,
        {
          DateRangeError:
            'Publish time should be greater than the current time.',
        },
      ]);
      setTimeout(() => {
        setFormDataError([]);
      }, 2000);
      return;
    }

    // This will trigger the form submission only if the condition is met

    if (errors.length > 0) {
      const errorMessage = errors.join('\n'); // Combine the errors into a single message
      showAlert(errorMessage, false); // Use your custom showAlert function
      return;
    }
    let freeData = [];
    const apiData = {
      name: formData.name,
      subtitle: formData.subtitle,
      description: formData.description,
      zoom_link: formData.zoom_link,
      created_by: formData.created_by,
      time_zone: formData.time_zone,
      start_date_time: eventPublishDateFormate, //eventPublishDateTime before we used this
      end_date_time: eventStartDateFormate, //eventStartDateTime before we used this
      camp: formData.camp,
      pay_by: formData.pay_by,
      is_free: formData.freeData,
      is_paid: formData.paid,
      is_root: false,
    };

    // Handling free/paid conditions and setting apiData
    // if (formData.free && !formData.paid) {
    if (formData.villagers) {
      freeData.push('is_root');
      apiData.is_root = true;
    }
    if (formData.seedlings) {
      freeData.push('seedling');
    }
    if (formData.all) freeData.push('all');

    apiData.is_free = freeData;
    // apiData.is_paid = false;
    apiData.pay_by = [''];
    // }
    // if (formData.paid) {
    //   apiData.is_free = [];
    //   apiData.is_paid = true;
    //   apiData.is_root = false;
    // }
    if (!apiData.is_free.length) {
      setFormDataError([
        ...formDataError,
        { checkboxError: 'Please select atlease one plan' },
      ]);
      setTimeout(() => {
        setFormDataError((prevErrors) =>
          prevErrors.filter((error) => !error.checkboxError)
        );
      }, 2000);
      return;
    }
    try {
      setIsLoading(true);
      if (id) {
        if (isUpdateImage && formData.picture) {
          const imageFormData = new FormData();
          imageFormData.append('image', formData.picture);
          await adminSpecialCampViewModel.addImageSpecialCamp(
            id,
            imageFormData
          );
        }
        const response =
          await adminSpecialCampViewModel.updateAdminSpecialCampData({
            id: id,
            ...apiData,
          });
        showAlert('dream camp update successufully', true);
        history.push('/r/special-camp-list');
      } else {
        const imageFormData = new FormData();
        if (formData.picture) {
          imageFormData.append('image', formData.picture);
        }
        const response = await adminSpecialCampViewModel.addAdminSpecialCamp(
          apiData
        );
        const id = response.id;
        if (id && imageFormData) {
          await adminSpecialCampViewModel.addImageSpecialCamp(
            id,
            imageFormData
          );
        }
        history.push('/r/special-camp-list');
        setFormData(initialData);
        showAlert('special camp add successufully', true);
      }
    } catch (error) {
      console.error('Error submitting special camp:', error);
      const usefulMessage = error?.serverResponse.error_info
        .split('\n')[2]
        .split(' (')[0]
        .trim();
      showAlert(
        usefulMessage ||
        'failed to special camp or check form detail, Please try again',
        false
      );
    } finally {
      setIsLoading(false);
    }
  };
  // useEffect(() => {
  //   if (!formData.seedlings && !formData.all && !formData.villagers) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       villagers: true, // Toggle only "Seedlings"
  //     }));
  //   }
  // }, [formData.seedlings, formData.villagers, formData.all]);
  // useEffect(() => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     villagers: false,
  //     seedlings: false,
  //     all: false, // Unselect "Paid" if "Free" is selected
  //   }));
  // }, [formData.paid]);

  const handleGetExpertListandCampList = async () => {
    if (!!schoolListIsLoading) return;
    const apiQuery = {
      ...querySchool,
    };

    if (!querySchool.starting_after) delete apiQuery.starting_after;
    setSchoolListIsLoading(true);

    try {
      const data = await adminSpecialCampViewModel.getSpeciaCampExpertList();
      const schoolList =
        await adminSpecialCampViewModel.getSpeciaCampSchoolList(
          undefined,
          apiQuery
        );
      const expertList = data?.map((item) => ({
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
      }));
      const campList = schoolList?.data.map((item) => ({
        id: item.id,
        name: item?.school_name,
      }));

      setexpertList(expertList);
      setavailableCamps((prevCamps) => [...prevCamps, ...campList]);
      setQuerySchool((prev) => ({
        ...prev,
        starting_after:
        schoolList?.data?.[schoolList?.data?.length - 1]?.id || null,
        has_more: schoolList?.has_more,
      }));
    } catch (error) { }
    finally{
      setSchoolListIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetExpertListandCampList();
  }, []);

  const currentDate = new Date().toISOString().split('T')[0];
  // const currentTime = new Date().toISOString().split('T')[1].slice(0, 5);
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          ...inputAutofillCss,
        }}
      >
        <Grid container spacing={2}>
          {/* Title */}
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>Title:</FormLabel>
              <Input
                disabled={!isPublished}
                type="text"
                name="name"
                placeholder="Title"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </Grid>

          {/* Subtitle */}
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>Subtitle:</FormLabel>
              <Input
                disabled={!isPublished}
                type="text"
                name="subtitle"
                placeholder="Subtitle"
                value={formData.subtitle}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Description:</FormLabel>
          <Textarea
            disabled={!isPublished}
            name="description"
            placeholder="Enter description"
            value={formData.description}
            onChange={handleInputChange}
            minRows={2}
            required
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl
            sx={{
              mt: 2,
              maxWidth: { xs: '100%', sm: '80%' },
              width: { xs: '100%', sm: '80%' },
            }}
          >
            <FormLabel>Picture:</FormLabel>
            <input
              required={
                !formData.picture ? (!updatefile ? true : false) : false
              }
              disabled={!isPublished}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </FormControl>
          <Box margin={{ xs: '16px', md: '', border: '1px solid #e3f2fd' }}>
            {previewImage || updatefile ? (
              <Image
                style={{ marginTop: 'auto' }}
                height="50px"
                width="50px"
                src={previewImage ? previewImage : updatefile}
              />
            ) : (
              ''
            )}
          </Box>
        </Box>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Zoom Link:</FormLabel>
          <Input
            disabled={!isPublished}
            type="url"
            name="zoom_link"
            placeholder="A link for kids and experts to join via Zoom"
            value={formData.zoom_link}
            onChange={handleInputChange}
            required
          />
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Expert:</FormLabel>
          <Select
            required
            placeholder="Please select an expert"
            disabled={!isPublished}
            name="created_by"
            value={formData.created_by}
            onChange={handleExpertSelectChange}
          >
            {expertList?.map((expert, index) => (
              <Option key={index} value={expert.id}>
                {expert.name}
              </Option>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Time Zone:</FormLabel>
          <Select
            required
            placeholder="Please select a timezone"
            disabled={!isPublished}
            name="time_zone"
            value={formData.time_zone}
            onChange={(e, value) =>
              setFormData({ ...formData, time_zone: value })
            }
          >
            {timezones?.map((tz) => (
              <Option key={tz.tzCode} value={tz.tzCode}>
                {tz.tzCode}
              </Option>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <FormLabel>Event Start Date & Time:</FormLabel>
          <Box
            sx={{
              display: 'flex',
              gap: 2, // Adjust spacing between inputs
              mt: 1, // Optional margin to create space between label and inputs
            }}
          >
            {/* Event Start Date */}
            <FormControl sx={{ flex: 1 }}>
              <Input
                disabled={!isPublished}
                type="date"
                name="eventStartDate"
                value={formData.eventStartDate}
                onChange={handleInputChange}
                required
                slotProps={{
                  input: { min: currentDate }, // Pass the min attribute via slotProps
                }}
              />
            </FormControl>

            {/* Event Start Time */}
            <FormControl sx={{ flex: 1 }}>
              <Input
                disabled={!isPublished}
                type="time"
                name="eventStartTime"
                value={formData.eventStartTime}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </Box>
          <FormLabel
            sx={{ py: '4px', fontFamily: 'cursive', fontSize: '12px' }}
          >
            (Note : Please select date-time greater than current date- time)
          </FormLabel>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormLabel>Event Publish Date & Time:</FormLabel>
          <Box
            sx={{
              display: 'flex',
              gap: 2, // Adjust spacing between inputs
              mt: 1, // Optional margin to create space between label and inputs
            }}
          >
            {/* Event Publish Date */}
            <FormControl sx={{ flex: 1 }}>
              <Input
                disabled={!isPublished}
                type="date"
                name="eventPublishDate"
                value={formData.eventPublishDate}
                onChange={handleInputChange}
                required
                slotProps={{
                  input: { min: currentDate }, // Pass the min attribute via slotProps
                }}
              />
            </FormControl>

            {/* Event Publish Time */}
            <FormControl sx={{ flex: 1 }}>
              <Input
                disabled={!isPublished}
                type="time"
                name="eventPublishTime"
                value={formData.eventPublishTime}
                onChange={handleInputChange}
                required
              // slotProps={
              //   {
              //     // input: { min: currentTime }, // Pass the min time to the input
              //   }
              // }
              />
            </FormControl>
          </Box>
          <FormLabel
            sx={{ py: '4px', fontFamily: 'cursive', fontSize: '12px' }}
          >
            (Note : Please select date-time greater than current date- time)
          </FormLabel>
          {formDataError.length > 0 && formDataError[0].DateRangeError && (
            <FormLabel sx={{ color: 'red', py: '4px' }}>
              {formDataError[0].DateRangeError}
            </FormLabel>
          )}
        </Box>

        <Box sx={{ mt: 2 }}>
          <FormLabel>Plans:</FormLabel>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {/* Free Plan */}
            <FormControl>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={!formData.paid}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      paid: !prev, // Unselect "Paid" if "Free" is selected
                      // seedlings: !prev.free, // Check "For All"
                      // villagers: !prev.free,
                      villagers: false,
                      seedlings: false,
                      all: false,
                    }))
                  }
                  disabled={!isPublished} // Disable "Free" if "Paid" is selected
                />
                <FormLabel sx={{ ml: 1 }}>Free</FormLabel>
              </Box>
            </FormControl>

            {/* Paid Plan */}
            <FormControl>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  disabled={!isPublished}
                  checked={formData.paid}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      paid: e.target.checked,
                      villagers: false,
                      seedlings: false,
                      all: false,
                    }))
                  }
                />
                <FormLabel sx={{ ml: 1 }}>Paid</FormLabel>
              </Box>
            </FormControl>
          </Box>

          {/* Free Plan Details */}
          {!formData.paid && (
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
                ml: { xs: 0, md: 4 },
              }}
            >
              {/* For All */}
              <FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={!isPublished}
                    checked={formData.all}
                    onChange={(e) =>
                      // setFormData((prev) => ({
                      //   ...prev,
                      //   seedlings: !prev.seedlings || !prev.villagers, // Toggle both
                      //   villagers: !prev.seedlings || !prev.villagers,
                      // }))
                      setFormData((prev) => ({
                        ...prev,
                        all: e.target.checked,
                        seedlings: true, // Toggle both
                        villagers: true,
                      }))
                    }
                  />
                  <FormLabel sx={{ ml: 1 }}>Emeralites</FormLabel>
                </Box>
              </FormControl>

              {/* Seedlings */}
              <FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={!isPublished}
                    checked={formData.seedlings || formData.all}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        seedlings: e.target.checked, // Toggle only "Seedlings"
                        villagers: !prev.villegers,
                      }));
                      // setFormData((prev) => ({
                      //   ...prev,
                      //   seedlings: !prev.seedlings,
                      //   villagers: !prev.seedlings ? true : prev.villagers, // Toggle only "Seedlings"
                      // }))
                      if (!e.target.checked) {
                        setFormData((prev) => ({
                          ...prev,
                          all: false,
                          villagers: true, // Toggle only "Villagers"
                        }));
                      }
                    }}
                  />
                  <FormLabel sx={{ ml: 1 }}>Seedlings</FormLabel>
                </Box>
              </FormControl>

              {/* Villagers */}
              <FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={!isPublished}
                    checked={
                      formData.villagers || formData.seedlings || formData.all
                    }
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        villagers: e.target.checked, // Toggle only "Villagers"
                      }));
                      if (!e.target.checked)
                        setFormData((prev) => ({
                          ...prev,
                          seedlings: false, // Toggle only "Villagers"
                          all: false,
                        }));
                    }}
                  />
                  <FormLabel sx={{ ml: 1 }}>Villagers</FormLabel>
                </Box>
              </FormControl>
            </Box>
          )}
          {/* free plan */}

          {/* paid plans */}
          {formData.paid && (
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
                ml: { xs: 0, md: 4 },
              }}
            >
              {/* For All */}
              <FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={!isPublished}
                    checked={
                      formData.villagers || formData.seedlings || formData.all
                    }
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        all: e.target.checked,
                      }));

                      if (!e.target.checked)
                        setFormData((prev) => ({
                          ...prev,
                          seedlings: false, // Toggle only "Villagers"
                          villagers: false,
                        }));
                    }}
                  />
                  <FormLabel sx={{ ml: 1 }}>Emeralites</FormLabel>
                </Box>
              </FormControl>

              {/* Seedlings */}
              <FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={!isPublished}
                    checked={formData.villagers || formData.seedlings}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        seedlings: e.target.checked,
                        // all: !prev.all, // Toggle only "Seedlings"
                      }));
                      // setFormData((prev) => ({
                      //   ...prev,
                      //   seedlings: !prev.seedlings,
                      //   villagers: !prev.seedlings ? true : prev.villagers, // Toggle only "Seedlings"
                      // }))
                      if (!e.target.checked) {
                        setFormData((prev) => ({
                          ...prev,
                          villagers: false,
                          all: true, // Toggle only "Villagers"
                        }));
                      }
                      if (e.target.checked) {
                        setFormData((prev) => ({
                          ...prev,
                          all: true, // Toggle only "Villagers"
                        }));
                      }
                    }}
                  />
                  <FormLabel sx={{ ml: 1 }}>Seedlings</FormLabel>
                </Box>
              </FormControl>

              {/* Villagers */}
              <FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={!isPublished}
                    checked={formData.villagers}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        villagers: e.target.checked,
                        seedlings: true, // Toggle only "Villagers"
                        all: true, // Toggle only "Villagers"
                      }));
                      // if (!e.target.checked)
                      //   setFormData((prev) => ({
                      //     ...prev,
                      //     seedlings: true, // Toggle only "Villagers"
                      //     all: true,
                      //   }));
                    }}
                  />
                  <FormLabel sx={{ ml: 1 }}>Villagers</FormLabel>
                </Box>
              </FormControl>
            </Box>
          )}
          {/* paid plan */}

          {formDataError.length > 0 && formDataError[0].checkboxError && (
            <FormLabel sx={{ color: 'red', py: '4px' }}>
              {formDataError[0].checkboxError}
            </FormLabel>
          )}
        </Box>

        {/* Invite Camps (Multi-select) */}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Invite Camps:</FormLabel>
          <Select
            disabled={!isPublished}
            name="camp"
            placeholder="Please select a camp"
            value={formData.camp}
            onChange={handleSelectChange}
            multiple
          >
            {/* {availableCamps?.map(
              (camp, index) =>
                camp.name && (
                  <Option key={index} value={camp?.id}>
                    {camp.name}
                  </Option>
                )
            )} */}
            <InfiniteScroll
              pageStart={0}
              loadMore={handleGetExpertListandCampList}
              hasMore={querySchool?.has_more}
              loader={<TaletreeLoader />}
              useWindow={false}
            >
              {availableCamps && availableCamps.length > 0 ? (
                availableCamps
                  ?.filter(camp => camp.name)
                  ?.map((camp, index) => (
                    <Option key={camp?.id || index} value={camp?.id}>
                      {camp?.name}
                    </Option>
                  ))
              ) : (
                <Option disabled value="">
                  No Data Found
                </Option>
              )}
            </InfiniteScroll>
          </Select>

        </FormControl>

        {/* {formData.camp.length > 0 && formData.paid && (
          <FormControl sx={{ mt: 2 }}>
            <FormLabel>Pay by:</FormLabel>
            <Select
              placeholder="Please select payment option"
              required={(formData.paid && formData.camp.length) || false}
              disabled={!isPublished}
              name="pay_by" // Name should match the state field you're updating
              value={formData.pay_by[0] || []}
              onChange={handleDropdownChange} // Correctly binding the function
            >
              <Option value="guardian">Guardian</Option>
              <Option value="camp">Camp</Option>
            </Select>
          </FormControl>
        )} */}

        {/* Submit Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: 1,
          }}
        >
          <Button
            disabled={!isPublished || isLoading}
            type="submit"
            variant="solid"
            color="primary"
          >
            {id ? 'Update' : 'Submit'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default SpecialCampForm;
