import { adminSpecialCampRepository } from '../../../repository/admin.specialcamp.repository';

const addAdminSpecialCamp = (params) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .addAdminSpecialCamp(params)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
const addImageSpecialCamp = (specialCampId, params) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .AddImageSpecialCamp(specialCampId, params)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const deleteAdminSpecialCampList = (id) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .deleteAdminSpecialCamps(id)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const updateAdminSpecialCampData = (data) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .updateAdminSpecialCamps(data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getSpeciaCampExpertList = () => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .getExpertList()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getSpeciaCampSchoolList = (camp_id, params) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .getCampList(camp_id, params)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const sendMailAdminSpecialCamp = (params) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .sendEmailAdminSpecialCamp(params)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
const publishAdminSpecialCamp = (id) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .publishAdminSpecialCamp(id)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
const getDependentListAttentdWithSpecialCamp = (id, searchQuery) => {
  return new Promise((resolve, reject) => {
    adminSpecialCampRepository
      .getDependentListAttentdWithSpecialCamp(id, searchQuery)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const adminSpecialCampViewModel = {
  addAdminSpecialCamp,
  deleteAdminSpecialCampList,
  updateAdminSpecialCampData,
  getSpeciaCampExpertList,
  getSpeciaCampSchoolList,
  sendMailAdminSpecialCamp,
  publishAdminSpecialCamp,
  addImageSpecialCamp,
  getDependentListAttentdWithSpecialCamp,
};
