import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();  // Ensure this is returning the correct base URL

function multiLoginChildHandle(dependent_id) {
    const url = `${BASE_URL}/switch?dependent_id=${dependent_id}`;
    return fetchUtils.post(url);
}

function multiLoginParentHandle() {
    const url = `${BASE_URL}/switch`
    return fetchUtils.post(url);
}

export const multiLoginRepository = {
    multiLoginChildHandle,
    multiLoginParentHandle
}
