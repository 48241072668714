import React, { useEffect, useState } from 'react';
import { adminSpecialCampViewModel } from './specialcamp.view.model';
import { adminSpecialCampRepository } from '../../../repository/admin.specialcamp.repository';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import TaletreeLoader from '../../../../components/views/taletree_loader/TaletreeLoader';
import DataTable from '../commonComponent/Table';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/joy/Chip';
import PaidIcon from '@mui/icons-material/Paid';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { useAuth } from '../../../../components/auth/Auth';
import AdminHome from '../../AdminHome';
let call_validate_timer = null;

const columns = [
  { field: 'id', headerName: 'ID', width: 80, withChip: false },
  { field: 'name', headerName: 'Name', width: 150, withChip: false },
  {
    field: 'dependentCount',
    headerName: 'Dependent Count',
    width: 140,
    withChip: false,
  },
  {
    headerName: 'Status',
    withChip: true,
    field: 'status',
    width: 120,
    renderChip: (row) => (
      <Chip
        variant="soft"
        size="sm"
        color={row.status ? 'danger' : 'success'}
        startDecorator={
          row.status ? (
            <UnpublishedIcon color="error" fontSize="small" />
          ) : (
            <CheckIcon color="success" fontSize="small" />
          )
        }
      >
        {row.status ? 'UnPublished' : 'Published'}
      </Chip>
    ),
  },
  {
    field: 'paid',
    headerName: 'Paid',
    withChip: true,
    width: 120,
    renderChip: (row) => (
      <Chip
        variant="soft"
        size="sm"
        color={row.paid ? 'success' : 'primary'}
        startDecorator={
          row.paid ? (
            <PaidIcon color="success" fontSize="small" />
          ) : (
            <CreditScoreIcon color="error" fontSize="small" />
          )
        }
      >
        {row.paid ? 'Paid' : 'Free'}
      </Chip>
    ),
  },
];

function ListSpecialCamp() {
  const history = useHistory();
  const { showAlert } = useAuth();
  const [limit] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [specialCamp, setSpecialCamp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false); // Add error state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allData, setAllData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [idForColoumnUpdate, setIdForColoumnUpdate] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [availableCamps, setavailableCamps] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [schoolListIsLoading, setSchoolListIsLoading] = useState(false);

  const extraActionMenu = [
    {
      action: 'sendMail',
      actionName: 'Preview E-Mail',
      color: 'warning',
    },
    {
      action: 'publish',
      actionName: 'Publish',
      color: 'primary',
    },
  ];
  const [query, setQuery] = useState({
    limit: limit,
    has_more: true,
    starting_after: null,
    title: '',
    camp: '',
    status: '',
    paid: '',
  });
  const [querySchool, setQuerySchool] = useState({
    limit: 10,
    has_more: true,
    starting_after: null,
  });

  const handleNext = (page) => {
    setCurrentIndex((pre) => pre + 1);
    if (currentIndex < allData.length - 1) {
      const lastItemId = specialCamp[specialCamp.length - 1]?.id;
      setSpecialCamp(allData[currentIndex + 1]);
    } else if (hasMore) {
      handleGetAdminSpecialCamp();
    }
  };

  const handleGetAdminSpecialCamp = async () => {
    try {
      setIsLoading(true);
      setError(false);
      const apiQuery = {
        ...query,
      };

      if (!query.starting_after) delete apiQuery.starting_after;
      if (query.camp === '') delete apiQuery.camp;
      if (query.paid === '') delete apiQuery.paid;
      if (query.status === '') delete apiQuery.status;
      const response = await adminSpecialCampRepository.getAdminSpecialCamps(
        apiQuery
      );

      if (response.status >= 400) {
        throw new Error('API Error');
      }
      const row = response?.data?.map((item) => ({
        id: item?.id,
        name: item?.name,
        email: item?.email || '',
        dependentCount: item?.dependent_count,
        paid: item?.is_paid,
        status: item?.is_draft,
        expert: item?.created_by
          ? `${item?.created_by.first_name || ''} ${
              item?.created_by.last_name || ''
            }`
          : '',
        date: item?.start_date_time || '',
        camp: Array.isArray(item?.camp_list) ? item?.camp_list : [],
        image: item.picture_url,
      }));

      // setSpecialCamp(row);
      setAllData([...allData, [...row]]);
      setSpecialCamp(row);
      const totalItems = response?.total || 0; // Assume `response.total` gives total items
      setTotalPages(Math.ceil(totalItems / limit));
      setIdForColoumnUpdate([...idForColoumnUpdate, row[row.length - 1]?.id]);
      if (row.length > 0) {
        const lastItemId = row[row.length - 1]?.id;
        setShouldFetch(false);
        setQuery((prevQuery) => ({
          ...prevQuery,
          starting_after: lastItemId,
          has_more: response?.has_more,
        }));
      }

      setHasMore(response?.has_more);
    } catch (error) {
      console.error('Error fetching dream camps:', error);
      setHasMore(false);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (id) => {
    history.push(`/r/special-camp/${id}`);
  };
  const handleDeleteAdminSpecialCamp = async (id) => {
    try {
      const response =
        await adminSpecialCampViewModel.deleteAdminSpecialCampList(id);
      if (response) {
        setSpecialCamp((prevSpecialCamp) =>
          prevSpecialCamp.filter((item) => item.id !== id)
        );
        const updatedData = allData.map((group) =>
          group.filter((row) => row.id !== id)
        );
        setAllData(updatedData);
        // setQuery({
        //   limit: limit,
        //   has_more: true,
        //   starting_after: null,
        // });
        // handleGetAdminSpecialCamp();
        showAlert('Delete Successfully', true);
      }
    } catch (error) {
      console.error('Error deleting special camp:', error);
      showAlert(
        error?.serverResponse.error_info ||
          'something went wrong! please try again letter',
        false
      );
    }
  };

  const getInfiniteFeed = () => {
    if (!isLoading && hasMore) {
      handleGetAdminSpecialCamp();
    }
  };

  useEffect(() => {
    if (shouldFetch) {
      handleGetAdminSpecialCamp();
    } else {
      setShouldFetch(true); // Reset fetch flag for next updates
    }
  }, [query]);

  const handlePrevious = () => {
    setCurrentIndex((pre) => pre - 1);
    setSpecialCamp(allData[currentIndex - 1]);
  };

  const handleGetExpertListandCampList = async () => {
    if (!!schoolListIsLoading) return;
    const apiQuery = {
      ...querySchool,
    };

    if (!querySchool.starting_after) delete apiQuery.starting_after;
    setSchoolListIsLoading(true);
    try {
      const response = await adminSpecialCampViewModel.getSpeciaCampSchoolList(
        undefined,
        apiQuery
      );

      const campList = response?.data?.map((item) => ({
        id: item.id,
        name: item?.school_name,
      }));
      setavailableCamps((prevCamps) => [...prevCamps, ...campList]);
      setQuerySchool((prev) => ({
        ...prev,
        starting_after:
          response?.data?.[response?.data?.length - 1]?.id || null,
        has_more: response?.has_more,
      }));
    } catch (error) {
      console.error(error, 'Error fetching camps');
    }
    setSchoolListIsLoading(false);
  };

  useEffect(() => {
    handleGetExpertListandCampList(); // Trigger fetching only when camps list is empty
  }, []);
  return (
    <AdminHome
      // breadCrumTitle="Dream Camp"
      route="/r/special-camp-add"
      breadcrumData={[{ title: 'Dream Camps', link: '/r/special-camp-list' }]}
      title="Dream Camps"
    >
      <DataTable
        onDelete={handleDeleteAdminSpecialCamp}
        onEdit={handleEdit}
        columns={columns}
        rows={specialCamp}
        actions={extraActionMenu}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handleNext}
        handlePrevious={handlePrevious}
        hasMore={hasMore}
        currentIndex={currentIndex}
        disabledNext={hasMore ? false : allData.length - 1 === currentIndex}
        isLoading={isLoading}
        setShouldFetch={setShouldFetch}
        setAllData={setAllData}
        currentRowData={setSpecialCamp}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setQuery={setQuery}
        query={query}
        availableCamps={availableCamps}
        searchFilter
        statusFilter
        campsFilter
        paidFilter
        noDataText="Dream Camp"
        searchLable="Search for dream camp"
        handleGetExpertListandCampList={handleGetExpertListandCampList}
        querySchool={querySchool}
      />
    </AdminHome>
  );
}

export default ListSpecialCamp;
