import React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Chip from '@mui/joy/Chip';
import Button from '@mui/joy/Button';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Divider from '@mui/joy/Divider';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

export default function TableCardDetail({
  columns,
  rows,
  actions = [],
  multiSelect = false,
  handlePrevious,
  handlePageChange,
  hasMore,
  handleActionClick,
  currentIndex,
  currentPage,
  isLoading,
  disabledNext,
  searchQuery,
  isNeedPagination = true,
  isAction,
}) {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  function ActionMenuItem({ row }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: {
              variant: 'plain',
              color: 'neutral',
              size: 'sm',
              sx: { position: 'absolute', top: 8, right: 8 },
            },
          }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          {actions.map((actionItem) => {
            return (
              <MenuItem
                hidden={
                  !row?.status &&
                  (actionItem.action === 'sendMail' ||
                    actionItem.action === 'publish')
                }
                key={actionItem.action}
                color={actionItem.color}
                onClick={() => handleActionClick(actionItem?.action, row)}
              >
                {actionItem?.actionName}
              </MenuItem>
            );
          })}
          <Divider />
        </Menu>
      </Dropdown>
    );
  }
  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
      }}
    >
      {rows.map((row) => (
        <Card
          key={row.id}
          variant="outlined"
          sx={{
            width: '300px',
            padding: 2,
            borderColor: selected.includes(row.id) ? 'primary' : 'neutral',
            position: 'relative',
          }}
        >
          {/* Multi-select checkbox */}
          {multiSelect && (
            <Checkbox
              checked={selected.includes(row.id)}
              onChange={() => {
                setSelected((prev) =>
                  prev.includes(row.id)
                    ? prev.filter((id) => id !== row.id)
                    : [...prev, row.id]
                );
              }}
              sx={{ position: 'absolute', top: 8, left: 8 }}
            />
          )}
          {isAction ? <ActionMenuItem row={row} /> : ''}
          {/* ID displayed at the top (only once) */}
          <Typography
            level="body-sm"
            sx={{ fontWeight: 'bold', textAlign: 'center', display: 'flex' }}
          >
            ID: {row.id}
          </Typography>

          {/* Render row data in two columns */}
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 1,
              mt: 2,
            }}
          >
            {columns.map((column) => {
              // Skip dependent count field
              // if (column.field === 'dependentCount') {
              //   return null; // Avoid rendering the dependent count field
              // }

              return (
                <React.Fragment key={column.field}>
                  {column.field !== 'id' && (
                    <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>
                      {column.withChip
                        ? column.renderChip(row)
                        : row[column.field]}{' '}
                      &nbsp;
                      {column.field === 'dependentCount' ? (
                        <Typography
                          component="div"
                          onClick={() => {
                            if (
                              row[column.field] !== 0 &&
                              row[column.field] !== '0'
                            ) {
                              handleActionClick('dependentList', row);
                            }
                          }}
                          sx={{
                            color: 'rgb(11,107,203) !important',
                            cursor: 'pointer',
                          }}
                          // href={`/dependents/${row.id}`}

                          style={{
                            textDecoration: 'none',
                            color: 'blue',
                          }}
                        >
                          View
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        </Card>
      ))}

      {/* Pagination controls */}
      {isNeedPagination && (
        <Box
          sx={{
            width: '100%',
            display: { xs: 'flex', sm: 'none' },
            justifyContent: 'space-around',
            gap: 1,
            my: '24px',
          }}
        >
          <Button
            size="sm"
            variant="outlined"
            disabled={currentIndex === 0 || !rows.length}
            onClick={() => handlePrevious()}
          >
            Previous
          </Button>
          {/* {[...Array(totalPages).keys()].map((page) => (
          <Button
            key={page + 1}
            size="sm"
            variant={page + 1 === currentPage ? 'solid' : 'outlined'}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Button>
        ))} */}
          <Button
            size="sm"
            variant="outlined"
            disabled={isLoading || disabledNext || !rows.length}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </Box>
      )}
    </Box>
  );
}
